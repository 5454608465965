import axios from "axios";
import { API_URL } from "@/const";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  mixins: [ selectionLocalStorage ],
  data() {
    return {
      selectGenerateStoreList: [], //入力テキストで絞る排出事業場選択肢
      // API返り値（選択肢などに仕様）
      prefectures: [],
      jwnetItemsOpt: [],
      recurryingTypesOpt: [],
      routesInfo: [],
      routeDetailInfo: {},
      wasteUnitOpt: [],
      confirmMemberOpt: [],
      shapeOpt: [],
      routeLabels: [],
      proxyGenerateCompanyList: [],
      disposalWayTypesOpt: [],
      wasteNameOpt: [],
      harmfulTypes: [], // 選択した有害物質種類が有害であったら
      generateStoreList: [], // 排出業者変更時にAPIから取得
      memberOpt: [], // 排出事業場変更時にAPIから取得
      generateMemberOpt: [], // 排出事業場変更時にAPIから取得
      collectMemberOpt: [],
      selectDisposalSiteList: [], //入力テキストで絞る処分場選択肢
      disposalSiteList: [],
      disposalSiteOptions: [], // 処分事業者IDで絞り込まずに、処分場選択肢
      selectCollectCompanyList: [], //入力テキストで絞る運搬事業者選択肢
      collectCompanyList: [],
      selectDisposalCompanyList: [], //入力テキストで絞る処分事業者選択肢
      disposalCompanyList: [],
    };
  },
  methods: {
    // CBA品目別廃棄物名称選択肢取得API    API_URL.DOMAIN.WASTE_NAME_CBA_ITEM
    getWasteNameCBAItemApi(cbaItemId) {
      axios
        .get(API_URL.DOMAIN.WASTE_NAME_CBA_ITEM(cbaItemId))
        .then((res) => {
          this.wasteNameOpt = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート処分方法選択肢取得API    API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE + routeId)
        .then((res) => {
          this.disposalWayTypesOpt = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 委託契約に紐づく廃棄物種類選択肢取得API    API_URL.ENTRUST.JWNET_ITEM_TYPE
    getEntrustJwnetItemsApi(generateStoreId) {
      axios
        .get(API_URL.ENTRUST.JWNET_ITEM_TYPE + `?generateStoreId=${generateStoreId}`)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 繰り返し情報選択肢取得API     API_URL.DOMAIN.RECURRING
    getRecurryingApi() {
      axios
        .get(API_URL.DOMAIN.RECURRING)
        .then((res) => {
          this.recurryingTypesOpt = res.data.recurringTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート選択肢検索Urlの作成
    getRouteListSearchApiUrl() {

      const params = new URLSearchParams();
      if (this.routeModalSearchParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.routeModalSearchParam.jwnetItemTypeId);
      if (this.routeModalSearchParam.routeName) params.append("routeName", this.routeModalSearchParam.routeName);
      if (this.routeModalSearchParam.wasteName) params.append("wasteName", this.routeModalSearchParam.wasteName);
      if (this.routeModalSearchParam.collectCompanyId) params.append("collectCompanyId", this.routeModalSearchParam.collectCompanyId);
      if (this.routeModalSearchParam.disposalCompanyId) params.append("disposalCompanyId", this.routeModalSearchParam.disposalCompanyId);
      if (this.routeModalSearchParam.disposalSiteId) params.append("disposalSiteId", this.routeModalSearchParam.disposalSiteId);

      if (this.routeModalSearchParam.wasteTypeIdList) params.append("wasteTypeIdList", this.routeModalSearchParam.wasteTypeIdList);
      if (this.routeModalSearchParam.industrialWasteJwnetTypeIdList) params.append("industrialWasteJwnetTypeIdList", this.routeModalSearchParam.industrialWasteJwnetTypeIdList);
      if (this.routeModalSearchParam.manifestTypeIdList) params.append("manifestTypeIdList", this.routeModalSearchParam.manifestTypeIdList);
      if (this.routeModalSearchParam.isFavorite) params.append("isFavorite", this.routeModalSearchParam.isFavorite);

      return API_URL.ROUTE.COLLECT_LIST+ this.formValue.generateStoreId + '/?' + params.toString();
    },

    // 運搬代理登録ルート選択肢取得API     API_URL.ROUTE.COLLECT_LIST
    getRouteListSearchApi() {
      axios
        .get(this.getRouteListSearchApiUrl())
        .then((res) => {
          this.routesInfo = res.data.routeList;
          this.narrowedRoutesList = [...this.routesInfo];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート情報API API_URL.ROUTE.DETAIL/{routeId}
    getRouteInfoApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          this.routeDetailInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 数量確定者選択肢取得API    API_URL.MANIFEST.CONFIRM_MEMBER
    getConfirmMemberApi(routeId) {
      axios
        .get(`${API_URL.MANIFEST.CONFIRM_MEMBER}/${routeId}`)
        .then((res) => {
          this.confirmMemberOpt = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート荷姿選択肢取得API    API_URL.ROUTE.ROUTE_SHAPE_TYPE
    getShapeApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_SHAPE_TYPE + routeId)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 代理登録排出事業者選択肢取得API    API_URL.MANIFEST.PROXY_GENERATE_COMPANY
    getProxyGenerateCompanyApi() {
      axios
        .get(API_URL.MANIFEST.PROXY_GENERATE_COMPANY)
        .then((res) => {
          this.proxyGenerateCompanyList = res.data.proxyGenerateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 排出事業者別排出事業場選択肢取得API    API_URL.MANIFEST.GENERATE_STORE/{generateCompanyId}
    // generateCompanyId は this.formValue.generateStoreId
    getGenerateStoreApi(generateCompanyId) {
      axios
        .get(API_URL.MANIFEST.GENERATE_STORE + generateCompanyId)
        .then((res) => {
          this.selectedGenerateStore = ''
          this.selectGenerateStoreList = res.data.generateStoreList;
          this.generateStoreList = res.data.generateStoreList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当者一覧API    API_URL.MANIFEST.MEMBER_OPT
    getMemberOptApi(generateStoreId) {
      return axios
        .get(API_URL.MANIFEST.MEMBER_OPT + generateStoreId)
        .then((res) => {
          this.memberOpt = res.data.MemberOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 排出事業場担当者一覧取得API    API_URL.MANIFEST.MEMBER_OPT_GENERATE/{generateCompanyId}
    // generateCompanyId は this.formValue.generateStoreId
    // getGenerateMemberApi(generateStoreId) {
    //   axios
    //     .get(API_URL.MANIFEST.MEMBER_OPT_GENERATE + generateStoreId)
    //     .then((res) => {
    //       this.generateMemberOpt = res.data.memberList;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getCollectMemberApi() {
      axios
        .get(API_URL.MANIFEST.TRANSPOTERS)
        .then((res) => {
          this.collectMemberOpt = res.data.memberList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getHarmfulApi() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分場選択肢取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        this.disposalSiteOptions = data;
      }).catch(() => {})
    },

    // 排出用、運搬事業者選択肢取得API
    getCollectCompanyForGenerate() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.collectCompanyList = data;
        this.selectCollectCompanyList = data;
      }).catch(() => {})
    },

    // 処分事業者選択肢取得API
    getDisposalCompany() {
      this.getDisposalCompanySelectionForCollect().then((data) => {
        this.selectDisposalCompanyList = data
        this.disposalCompanyList = data;
      }).catch(() => {})
    },
  },
};
