export default {
  methods: {
    addTextareaHandler() {
      if (this.formValue.remarksInfo.remarksList.length >= 5) return;
      this.formValue.remarksInfo.remarksList.push({
        remarks: "",
      });
      this.validate.remarks.push({
        isValid: true,
        errMsg: ""
      });
    },
    // テキストエリア削除
    removeTextareaHandler(ta) {
      this.formValue.remarksInfo.remarksList = this.formValue.remarksInfo.remarksList.filter((t) => t != ta);
      this.validate.remarks.pop();
    },

  },
};
